import React from 'react'
import styled from '@emotion/styled'
import Fade from 'react-reveal/Fade'
import { InlineWidget } from "react-calendly";

import Theme, { Mq } from '../themes/default'
import { H1 } from '../atoms/heading'
import Layout from '../organisms/layout'
import SEO from '../organisms/seo'
import { Paragraph } from '../atoms/text'
import checkmarkIcon from '../assets/images/green-check.svg';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 3rem 5%;
  margin: 10% 0;
  height: 100%;

  ${Mq.md} {
    padding: 2rem 3%;
    margin: 3% 5%;
  }
  ${Mq.xxl} {
    margin: 0 12%;
  }
`

const DescriptionText = styled(Paragraph)`
  max-width: 45rem;
  text-align: center;
`

const CheckmarkIcon = styled.img`
  width: 80px;
  height: 80px;
  margin: 2rem 0;

  ${Mq.md} {    
    width: 100px;
    height: 100px;
  }
`;

const CalendlyWidgetWrapper = styled.div`
  width: 100vw;
  height: 700px;
`;

const ThankYou = () => {
  return (
    <Layout>
      <SEO
        description="BiasSync Confirmed Thank You Form"
        title="Thank You"
        meta={[
          {
            name: 'robots',
            content: 'noindex, nofollow, nosnippet'
          }
        ]}
      />
      <Wrapper>
        <Fade bottom={true} distance={Theme.fadeDistance}>
          <CheckmarkIcon alt="" src={checkmarkIcon} />
          <H1>Thank You!</H1>
          <DescriptionText largeText={true}>
            Your submission has been sent and a representative will get back to you shortly. In the interim, please feel free to schedule a meeting via the link below.
          </DescriptionText>
          <CalendlyWidgetWrapper>
            <InlineWidget url="https://calendly.com/xavier-robinson/30min" styles={{height: '100%', width: '100%'}}/>
          </CalendlyWidgetWrapper>
        </Fade>
      </Wrapper>
    </Layout>
  )
}

export default ThankYou
